#root {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -5;
  background-image: url('../../images/paper_texture.png');
  background-size: 20%;
  background-repeat: repeat;
}

.application {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $th-white-opaque;
}

.application-sidebar {
  position: absolute;
  top: 5%;
  bottom: 80%;
  right: auto;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 20;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $th-navy-opaque;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-80deg);
    -ms-transform: skew(-80deg);
    transform: skew(-80deg);
    z-index: -1;
    box-shadow: 0px 0px 5px 0px $th-navy;
  }
}

#application-body {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
  z-index: 3;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  &>div {
    height: 90%;
    width: 100%;
    padding-top: 15%;
  }
}