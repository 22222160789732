.application-header {
  background-color: $th-red;
  width: 100%;
  padding: 0;
  border-top: 2px solid $th-navy;
  border-bottom: 4px solid $th-navy;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 5%;
  bottom: auto;
  box-shadow: inset 0px 0px 60px -50px $th-navy, 0px 1px 20px 0px $th-navy;
  box-sizing: content-box;
  z-index: 10;
}

.header-wordmark {
  position: absolute;
  left: 7.5%;
  top: 0;
  bottom: 0;
  right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 60%;
  box-sizing: border-box;
  &>h1 {
    width: 100%;
    height: 100%;
    font-size: 7.5vw;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    transition: all .1s ease-in-out;
    z-index: 11;
    cursor: pointer;
    & a {
      display: flex;
      align-items: flex-end;
      height: 100%;
      color: $th-navy;
      text-decoration: none;
      padding: 0;
      margin: 0;
    }
    &:hover {
      transform: scale(1.02);
      text-shadow: 0 0 5px $th-white-opaque;
    }
  }
  &>p {
    display: none;
  }
}

#gg-overlay {
  height: 100%;
  width: 10%;
  position: absolute;
  right: auto;
  left: 0;
  top: auto;
  bottom: 0;
  z-index: 10;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: right bottom;
  opacity: 0.2;
}

#uss-overlay {
  height: 100%;
  width: 10%;
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 10;
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: left bottom;
  opacity: 0.175;
}

#header-menu-trigger {
  position: absolute;
  font-family: 'TH-Icons';
  height: 100%;
  width: 25%;
  top: auto;
  bottom: 0;
  left: auto;
  right: 7.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;
  &>h2 {
    display: none;
  }
}

#nav-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  &>li {
    transition: all .1s ease-out;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    z-index: 20;
    margin-top: 10%;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      &>a {
        font-weight: 700;
        text-shadow: 0 0 5px $th-white-opaque;
      }
    }
  }
  & a {
    width: 100%;
    text-decoration: none;
    color: $th-navy;
    font-size: 2vw;
    font-weight: 500;
    color: $th-navy;
  }
}