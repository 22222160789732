#resume {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  &>button {
    width: 20%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 25px;
    border: 2px solid $th-red;
    background-color: $th-red;
    border-radius: 10px;
    color: $th-white;
    opacity: 0.8;
    cursor: pointer;
    font-weight: 700;
    box-shadow: 0px 0px 5px 0px $th-navy;
    &:hover {
      opacity: 1;
    }
  }
}

#resume-iframe-container {
  position: relative;
  height: 90%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center center;
  &>iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: none;
    border: 2px solid $th-white;
    border-radius: 10px;
    box-shadow: 0 0 25px $th-navy;
    z-index: 10;
  }
}