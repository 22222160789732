#projects {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.my-projects {
  width: 70%;
  height: 90%;
  background-color: $th-navy-opaque;
  box-shadow: 0 0 5px $th-navy;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &>p {
    height: 5%;
    width: 100%;
    color: $th-navy;
    text-align: center;
    font-size: 21px;
    padding: 0.5% 0;
    margin: 0;
    font-family: 'Permanent Marker', cursive;
    opacity: 0.9;
  }
}

.project-pane {
  position: relative;
  width: 100%;
  height: 95%;
  box-sizing: border-box;
}

.selected-project {
  position: absolute;
  right: 0;
  top: auto;
  bottom: 5%;
  left: 0;
  width: 100%;
  height: 90%;
  border-top: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  transition: all .1s ease-in-out;
  overflow: hidden;
}

.project-assets {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  &>div.project-links {
    width: 15%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    &>a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 20%;
      border-radius: 10px;
      background-color: $th-red;
      cursor: pointer;
      transition: all .1s ease-in-out;
      outline: none;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
      margin: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &>img {
    margin-right: 5%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid $th-navy;
    box-shadow: 0 0 5px $th-white-opaque;
  }
}

.project-description {
  width: 100%;
  height: 50%;
  overflow-y: scroll;
  // box-shadow: inset 0 -10px 10px -9px $th-white-opaque;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
}

.project-selectors {
  position: absolute;
  right: 0;
  top: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  height: 5%;
  display: flex;
  &>.project-selector {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25.1%;
    height: 100%;
    padding: 1%;
    transition: all .2s ease-in-out;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    filter: grayscale(0.9);
    opacity: 0.8;
    &>img {
      max-height: 140%;
      max-width: 40%;
    }
    &>p {
      width: 75%;
      margin-left: 2%;
      font-size: 18px;
    }
    &.active {
      filter: grayscale(0);
      opacity: 1;
      cursor: auto;
      border-bottom: none;
      & * { transform: scale(1.1); }
    }
  }
}

.technologies-pane {
  width: 20%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  background-color: $th-red-opaque;
  border-radius: 10px;
  box-shadow: 0 0 5px $th-navy;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  &>p {
    width: 100%;
    color: $th-navy;
    text-align: center;
    font-size: 21px;
    padding: 1% 0 1% 0;
    margin: 0 0 2% 0;
    font-family: 'Permanent Marker', cursive;
    opacity: 0.9;
  }
}

.my-technologies {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  list-style: none;
  border-radius: 10px;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  &>li {
    height: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &>svg { width: 30%; max-height: 90%; }
    &>p {
      width: 70%;
      line-height: 100%;
      color: $th-navy;
      text-align: left;
      font-weight: 700;
      font-size: 18px;
      font-style: italic;
      opacity: 0.8;
    }
  }
}

.site-about {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  width: 100%;
  height: 5%;
  padding: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // border: 2px solid $th-navy;
  border-top: none;
  background-color: $th-navy-opaque;
  &>p {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: $th-navy;
    font-style: italic;
    opacity: 0.9;
    font-family: 'Permanent Marker', cursive;
    &>a {
      text-decoration: none;
      padding-left: 3px;
      font-weight: 900;
      color: inherit;
      transition: all .1s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}