$th-red: #c0362c;
$th-navy: #001429;
$th-light-blue: #A7BED3;
$th-yellow: #FFFF99;
$th-white: #F4F6F4;
$th-white-opaque: rgba(244, 246, 244, 0.25);
$th-red-opaque: rgba(192, 54, 44, 0.25);
$th-navy-opaque: rgba(0, 20, 41, 0.25);

$aw-blue: rgba(61, 100, 202, 0.5);
$cdt-white: rgba(255, 253, 245, 0.5);
$cdt-red: rgba(143, 63, 54, 0.2);
$cdt-blue: rgba(55, 97, 113, 0.2);
$hsp-gray: rgb(60, 60, 62);
$hsp-green: rgb(3, 113, 60);
$test:rgb(246, 245, 245);