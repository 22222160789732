#about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
}

.about-img {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 2.5%;
  &>img {
    max-width: 100%;
    max-height: 50%;
    border-radius: 50%;
    box-shadow: 0 0 25px $th-navy;
    z-index: 15;
    border: 2px solid $th-white;
  }
}

.about-text {
  height: 100%;
  min-width: 55%;
  max-width: 60%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 2.5% 2.5% 5% 2.5%;
  overflow-y: scroll;
  box-sizing: border-box;
  z-index: 20;
  &>h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: $th-red;
    font-size: 2.25vw;
    white-space: nowrap;
    &>strong { font-family: 'Permanent Marker', cursive; font-size: 2.5vw; }
  }
  &>p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: $th-navy;
    font-size: 1.25vw;
    text-align: left;
    line-height: 1.1;
    padding: 0;
    opacity: 0.8;
    font-style: italic;
  }
}

.currently-playing {
  width: 100%;
  height: 45%;
  box-sizing: content-box;
  &>h3 {
    font-size: 1vw;
    color: $th-red;
  }
  &>iframe {
    height: 80%;
    width: 100%;
    border-radius: 10px;
    border: 2px solid $th-white;
    box-shadow: 0 0 25px $th-navy;
  }
}