#root {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -5;
  background-image: url('../../images/paper_texture.png');
  background-size: 20%;
  background-repeat: repeat;
}

.application {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $th-white-opaque;
}

.application-sidebar {
  position: absolute;
  top: 10%;
  bottom: 0;
  right: auto;
  left: 0;
  width: 20%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $th-navy-opaque;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-15deg);
    -ms-transform: skew(-15deg);
    transform: skew(-15deg);
    z-index: -1;
    box-shadow: 0px 0px 5px 0px $th-navy;
  }
}

#application-body {
  position: absolute;
  top: 10%;
  bottom: 0;
  right: 0;
  left: auto;
  width: 100%;
  overflow-y: scroll;
  z-index: 3;
  padding: 0 0 0 20%;
  box-sizing: border-box;
  &>div {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 1% 5% 1% 0;
  }
}