#body-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 60%;
  width: 90%;
  margin: 30% 0 10% 10%;
  &>li {
    &>a {
      font-family: 'Permanent Marker', cursive;
      transition: all .1s ease-in-out;
      color: $th-navy;
      font-size: 21px;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        color: $th-red;
      }
      &.active {
        color: $th-red;
        font-size: 25px;
        opacity: 1;
      }
    }
  }
}