#contact {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  &>.form-text {
    width: 60%;
    margin-bottom: 15%;
    background-color: $th-navy-opaque;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px $th-navy;
    padding: 1% 2%;
    &>h1 {
      margin: 0;
      text-align: left;
      color: $th-red;
      font-size: 25px;
    }
  }
  &>.form-img {
    width: 30%;
    margin-top: 20%;
  }
}

div[class^=contact-form-row-] {
  display: flex;
  width: 100%;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0;
  &>* {
    height: 100%;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }
}

.contact-form-row-one>input {
  width: 47%;
  height: 100%;
  background-color: $th-white;
  border: 2px solid transparent;
  border-radius: 5px;
  opacity: 0.8;
  padding: 1% 1%;
  font-weight: 700;
  font-size: 18px;
  &:hover {
    border: 2px solid $th-red;
    opacity: 1;
  }
  &:focus {
    border: 2px solid $th-red;
    outline: none;
    opacity: 1;
  }
}

.contact-form-row-two {
  height: 80%;
  &>textarea {
    width: 100%;
    background-color: $th-white;
    border: 2px solid transparent;
    border-radius: 5px;
    opacity: 0.8;
    padding: 2% 1%;
    font-size: 18px;
    &:hover {
      border: 2px solid $th-red;
      opacity: 1;
    }
    &:focus {
      border: 2px solid $th-red;
      outline: none;
      opacity: 1;
  }
  }
}

.contact-form-row-three>button {
  width: 20%;
  height: 40px;
  background-color: $th-red;
  border: 2px solid $th-red;
  border-radius: 5px;
  color: $th-white;
  font-weight: 700;
  outline: none;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 21px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.form-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &>img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 25px $th-navy;
    z-index: 10;
    border: 2px solid $th-white;
  }
}

.form-feedback {
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  &>p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-left: 2%;
  }
}

.form-error { color: $th-red; }
.form-success { color: $th-navy; }