@import './stylesheets/desktop/reset.scss';
@import './stylesheets/desktop/theme.scss';

@media only screen and (min-width: 1000px) {
  @import './stylesheets/desktop/header.scss';
  @import './stylesheets/desktop/content.scss';
  @import './stylesheets/desktop/layout.scss';
  @import './stylesheets/desktop/sidebar.scss';
}

@media only screen and (max-width: 999px) {
  @import './stylesheets/mobile/header_mobile.scss';
  @import './stylesheets/mobile/layout_mobile.scss';
  @import './stylesheets/mobile/sidebar_mobile.scss';
  @import './stylesheets/mobile/content_mobile.scss';
}