.application-header {
  background-color: $th-red;
  width: 100%;
  padding: 0;
  border-top: 2px solid $th-navy;
  border-bottom: 4px solid $th-navy;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10%;
  min-height: 56px;
  bottom: auto;
  box-shadow: inset 0px 0px 60px -50px $th-navy, 0px 1px 20px 0px $th-navy;
  box-sizing: content-box;
  z-index: 100;
}

.header-wordmark {
  position: absolute;
  left: 7%;
  top: 0;
  bottom: 0;
  right: auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  height: 100%;
  width: 68%;
  box-sizing: border-box;
  &>a {
    height: 100%;
    font-size: 7.75vh;
    padding: 0;
    margin: 0;
    transition: all .1s ease-in-out;
    z-index: 11;
    cursor: pointer;
    white-space: nowrap;
    clear: both;
    font-family: 'Permanent Marker', cursive;
    color: $th-navy;
    text-align: left;
    box-sizing: border-box;
    text-decoration: none;
    &:hover {
      transform: scale(1.02);
      text-shadow: 0 0 5px $th-white-opaque;
    }
  }
  &>p {
    height: 100%;
    font-size: 3vh;
    font-weight: 700;
    font-style: italic;
    color: $th-navy;
    padding: 0;
    margin: 0;
    text-align: left;
    box-sizing: border-box;
    padding-left: 5%;
    white-space: nowrap;
  }
}

#gg-overlay {
  height: 100%;
  width: 8%;
  position: absolute;
  right: auto;
  left: 0;
  top: auto;
  bottom: 0;
  z-index: 10;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  opacity: 0.2;
}

#uss-overlay {
  height: 100%;
  width: 8%;
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 10;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
  opacity: 0.175;
}

#header-menu-trigger {
  position: absolute;
  font-family: 'TH-Icons';
  height: 40%;
  width: 10%;
  top: auto;
  bottom: 2%;
  left: auto;
  right: 7%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 11;
  padding-top: 60%;
  &>h2 {
    transition: all .1s ease;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    vertical-align: bottom;
    font-size: 3vh;
    color: $th-navy;
    cursor: pointer;
    &>i {
      margin-bottom: 2.5%;
    }
  }
  &>ul {
    visibility: hidden;
    width: 0%;
    &>li {
      visibility: hidden;
    }
  }
  &:hover{
    width: 30%;
    &>h2 {
      width: 33.33%;
    }
    &>ul {
      visibility: visible;
      width: 66.67%;
      &>li {
        visibility: visible;
      }
    }
  }
}

#nav-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  box-sizing: border-box;
  overflow: hidden;
  &>li {
    transition: all .1s ease-out;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 0;
    z-index: 20;
    margin-bottom: 2.5%;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      &>a {
        font-weight: 700;
        text-shadow: 0 0 5px $th-white-opaque;
      }
    }
  }
  & a {
    width: 100%;
    text-decoration: none;
    color: $th-navy;
    font-size: 2.25vh;
    font-weight: 500;
    color: $th-navy;
  }
}

@media only screen and (max-width: 1192px) {
  .header-wordmark {
    &>h1 {
      padding-right: 10%;
    }
  }
}