#about {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  position: relative;
  flex-direction: column;
}

.about-img {
  height: 20%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  &>img {
    width: 50%;
    border-radius: 50%;
    box-shadow: 0 0 25px $th-navy;
    z-index: 15;
    border: 2px solid $th-white;
  }
}

.about-text {
  height: 80%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  overflow-y: scroll;
  &>h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: $th-red;
    font-size: 25px;
    &>strong { font-family: 'Permanent Marker', cursive; font-size: 25px; }
  }
  &>p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: $th-navy;
    font-size: 16px;
    text-align: left;
    line-height: 1.1;
    padding: 0 4% 1% 4%;
    opacity: 0.8;
    font-style: italic;
  }
}

.currently-playing {
  display: none;
}